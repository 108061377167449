import React, { useContext } from 'react';
import FunnelContext from '../../context/FunnelContext';

const DelayText = props => {
  const { text } = props;
  const { deliveryDate } = useContext(FunnelContext);

  const InnerHTML = () => {
    let currentText = text;

    currentText = currentText?.replace(
      /\[delivery-date]/gm,
      deliveryDate || '...'
    );

    return {
      __html: currentText
    };
  };

  return <div {...props.attributes} dangerouslySetInnerHTML={InnerHTML()} />;
};

export default DelayText;
